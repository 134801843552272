<template>
  <vbt-content-box
    :loading-error="loadingError"
    :loading="loading"
    title="Tags"
  >
    <v-flex
      class="mt-n3"
      sm12
    >
      <router-link
        v-for="tag in tags"
        :key="tag.tagKey"
        :to="{
          name: 'orders',
          query: {
            pageNum: '1',
            tags: [tag.tagKey]
          },
        }"
        target="_blank"
      >
        <v-chip
          small
          color="success"
          class="mx-1 mt-3"
          style="cursor: pointer;"
        >
          <strong>
            #{{ tag.tagName }}
          </strong>
        </v-chip>
      </router-link>
    </v-flex>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

import { prepareTagsData } from '../_helpers/tags';

export default {
  name: 'OrderTags',

  props: {
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },

  computed: {
    ...mapState('orders', {
      tags: ({ order: { tags } }) => prepareTagsData(tags || []),
    }),
  },
};
</script>
