import { mapState, mapGetters } from 'vuex';

import { formatDisplayDate, generateXHRError } from '@helpers';

import TrackingInfoViewer from '../trackingInfoViewer';

export default {
  name: 'OrderMainInfo',
  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },
  components: {
    TrackingInfoViewer,
  },
  computed: {
    ...mapState('orders', ['order']),
    ...mapGetters('orders', ['orderInfo']),
    noAvailableTracking() {
      const { orderItemsStatuses = [] } = this.order;
      return orderItemsStatuses.some(({ trackingNumber }) => !trackingNumber);
    },
  },
  methods: {
    showSendToProcessPrompt() {
      this.$VBlackerTheme.alert.warning({
        text: 'Send processed request?',
      }, this.sendOrderTrackingProcessedRequest);
    },
    async sendOrderTrackingProcessedRequest() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.$http.post(`orders/${this.$route.params.id}/tracking`);
        this.$VBlackerTheme.notification.success('Order tracking successfully processed');
      } catch (e) {
        this.$VBlackerTheme.notification.error(generateXHRError(e));
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
    formatDisplayDate,
  },
};
