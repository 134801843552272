<template>
  <vbt-content-box
    :loading="chargesLoading"
    :no-content="!charges.length"
    title="Charges"
  >
    <template #toolbar>
      <charge-adder
        :order-id="orderId"
        @success="getChargesByOrderId"
      />
    </template>

    <ir-invoice-charges-list
      :items="charges"
      :page-size="charges.length"
      :hided-columns="['orderId']"
      actions
      v-on="$listeners"
    />
  </vbt-content-box>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';
import { IrInvoiceChargesList } from '@modules/costs/invoiceCharges';

import { OrderChargesApiService } from '../../_services/charges.api.service';
import ChargeAdder from './_components/ChargeAdder.vue';

export default {
  name: 'IrOrderCharges',

  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },

  components: {
    IrInvoiceChargesList,
    ChargeAdder,
  },

  data() {
    return {
      charges: [],
      chargesLoading: false,
    };
  },

  watch: {
    orderId() {
      this.getChargesByOrderId();
    },
  },

  created() {
    this.getChargesByOrderId();
  },

  methods: {
    getChargesByOrderId() {
      this.wrapToLoadingFn({
        req: OrderChargesApiService.getChargesByOrderId.bind(null, { orderId: this.orderId }),
        loadingFlagName: 'chargesLoading',
        onSuccess: r => this.$set(this, 'charges', r),
      });
    },

    wrapToLoadingFn,
  },
};
</script>
