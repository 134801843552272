import { mapGetters } from 'vuex';

import { formatNumber } from '@helpers';

const pricesListHeaders = Object.freeze([
  { text: 'Print Provider', value: 'accountId', width: '20%' },
  { text: 'Item', value: 'orderItems', width: '10%' },
  { text: 'Total Weight', value: 'totalWeight', width: '10%' },
  { text: 'Ship Type', value: 'shipType', width: '20%' },
  { text: 'Print Cost', value: 'printCosts', width: '10%' },
  { text: 'Ship Cost', value: 'shipCost', width: '10%' },
  { text: 'Total Cost', value: 'totalCost', width: '15%' },
]);

export default {
  name: 'OrderPrices',
  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },
  data() {
    return {
      isShort: true,
      pricesListHeaders,
    };
  },
  computed: {
    ...mapGetters('orders', ['orderPrices']),
    ...mapGetters('accounts', ['accountNameByIdFromAllAccounts']),
    rows() {
      return this.isShort ? this.orderPrices.slice(0, 3) : this.orderPrices;
    },
    noContent() {
      return { show: !this.rows.length, text: 'No prices' };
    },
  },
  methods: {
    formatNumber,
    getTotalPricePercentageDifference(firstPrice, currentPrice) {
      if (!firstPrice || !currentPrice) {
        return '';
      }
      const difference = Math.abs(((firstPrice - currentPrice) / firstPrice) * 100);
      return difference < 0.5 ? '(<1%)' : `(+${Math.round(difference)}%)`;
    },
  },
};
