<template>
  <vbt-dialog
    v-model="dialog"
    :disable-submit-btn="disableSubmit"
    submit-btn-color="warning"
    submit-btn-name="Reroute"
    title="Mass reroute"
    width="600px"
    @submit="runMassReroute"
  >
    <v-layout
      justify-space-between
      class="mb-3"
      align-center
    >
      <v-flex sm8>
        <v-select
          v-model="reroutePrintProvider"
          :disabled="!selectedOrderItemsGeneratedIds.length"
          :loading="accountsLoading"
          :items="accountsOptions"
          label="Print provider"
        />
      </v-flex>

      <v-checkbox
        v-model="isForceReroute"
        :disabled="!selectedOrderItemsGeneratedIds.length"
        class="vbt-hide-form-control-details mt-2 mr-5"
        label="Force reroute"
        color="warning"
      />
    </v-layout>

    <v-layout
      justify-space-between
      class="mb-3"
      align-center
    >
      <v-checkbox
        v-model="isReestimate"
        :disabled="!selectedOrderItemsGeneratedIds.length"
        class="vbt-hide-form-control-details mt-2 mr-5"
        label="Re-estimate"
        color="warning"
      />
    </v-layout>

    <v-layout justify-space-between>
      <strong>
        Order Items:
      </strong>

      <span
        v-if="!selectedOrderItemsGeneratedIds.length"
        class="warning--text"
      >
        Select at least one order
      </span>
    </v-layout>
    <v-divider class="my-2" />

    <vbt-table
      :headers="headers"
      :items="orderItems"
      :page-size="orderItems.length"
    >
      <template #productType="{ item: { productType, id } }">
        {{ productType }} ({{ id }})
      </template>
      <template #checkboxes="{ item: { generatedId } }">
        <v-checkbox
          :input-value="selectedOrderItemsGeneratedIds.includes(generatedId)"
          class="vbt-hide-form-control-details"
          color="warning"
          @click="updateSelectedOrderItemsGeneratedIdsWith(generatedId)"
        />
      </template>
    </vbt-table>
  </vbt-dialog>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import { OrdersApiService } from '../_services/orders.api.service';

const headers = Object.freeze([
  { text: '', value: 'checkboxes', width: '10px' },
  { text: 'Product Type', value: 'productType' },
  { text: 'Quantity', value: 'quantity', width: '110px' },
]);

export default {
  name: 'IrOrderMassReroute',

  data() {
    return {
      selectedOrderItemsGeneratedIds: [],
      reroutePrintProvider: '',
      isForceReroute: false,
      isReestimate: false,
      orderItems: [],
      orderId: '',

      accountsLoading: false,
      accountsOptions: [],

      dialog: false,
      headers,
    };
  },

  computed: {
    disableSubmit() {
      return !this.reroutePrintProvider || !this.selectedOrderItemsGeneratedIds.length;
    },
  },

  watch: {
    dialog(isOpen) {
      if (isOpen) {
        this.getAccounts();
      }
    },

    isForceReroute(force) {
      this.getAccounts({ force });
    },
  },

  methods: {
    runMassReroute() {
      this.$VBlackerTheme.alert.warning({
        text: `Run mass reroute to ${this.reroutePrintProvider}?`,
      }, () => this.wrapToLoadingFn({
        req: OrdersApiService.runMassReroute.bind({}, {
          generatedIds: this.selectedOrderItemsGeneratedIds,
          printProvider: this.reroutePrintProvider,
          force: this.isForceReroute,
          reestimate: this.isReestimate,
          orderId: this.orderId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Order successfully rerouted');
          this.$emit('refresh');
          this.dialog = false;
        },
      }));
    },

    getAccounts({ force = this.isForceReroute } = {}) {
      if (!this.selectedOrderItemsGeneratedIds.length) {
        return false;
      }

      return this.wrapToLoadingFn({
        req: OrdersApiService.getMassRerouteAccounts.bind({}, {
          generatedIds: this.selectedOrderItemsGeneratedIds,
          printProvider: this.reroutePrintProvider,
          orderId: this.orderId,
          force,
        }),
        loadingFlagName: 'accountsLoading',
        onSuccess: (r) => {
          this.$set(this, 'accountsOptions', r.map(account => ({
            text: `${account.fullName} (${account.name})`,
            value: account.name,
          })));

          const needChangeReroutePrintProvider = this.accountsOptions.length
            && !this.accountsOptions.some(({ value }) => value === this.reroutePrintProvider);

          if (needChangeReroutePrintProvider) {
            this.reroutePrintProvider = this.accountsOptions[0]?.value ?? '';
          }
        },
      });
    },

    updateSelectedOrderItemsGeneratedIdsWith(generatedId) {
      if (this.selectedOrderItemsGeneratedIds.includes(generatedId)) {
        this.selectedOrderItemsGeneratedIds = this.selectedOrderItemsGeneratedIds
          .filter(v => v !== generatedId);
      } else {
        this.selectedOrderItemsGeneratedIds.push(generatedId);
      }

      this.getAccounts();
    },

    /**
     * @param orderId - ID of reroute order
     * @param orderItems - array of {
     *   generatedId,
     *   productType,
     *   quantity,
     * }
     */
    $_openDialog({ orderItems, orderId }) {
      this.selectedOrderItemsGeneratedIds = orderItems.map(({ generatedId }) => generatedId);
      this.orderItems = orderItems;
      this.isForceReroute = false;
      this.isReestimate = false;
      this.orderId = orderId;

      this.dialog = true;
    },

    wrapToLoadingFn,
  },
};
</script>
