import { mapGetters } from 'vuex';

import IrOrderMassReroute from '../IrOrderMassReroute.vue';
import OrderItemActions from './_components/OrderItemActions.vue';

export default {
  name: 'OrderItems',

  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },

  components: {
    IrOrderMassReroute,
    OrderItemActions,
  },

  data() {
    return {
      isShort: true,
    };
  },

  computed: {
    ...mapGetters('orders', ['orderItems']),
    ...mapGetters('accounts', ['accountNameByIdFromAllAccounts']),

    rows() {
      return this.isShort ? this.orderItems.slice(0, 3) : this.orderItems;
    },

    noContent() {
      return { show: !this.orderItems.length, text: 'No items' };
    },
  },

  methods: {
    getTrackingNumbersArray(trackingNumberStr) {
      if (!trackingNumberStr) {
        return ['-'];
      }
      return trackingNumberStr.split('|');
    },

    openIrOrderMassRerouteDialog() {
      this.$refs.IrOrderMassReroute.$_openDialog({
        orderId: this.$route.params.id,
        orderItems: this.orderItems,
      });
    },
  },
};
