import { mapState, mapActions } from 'vuex';

const activitiesListHeaders = Object.freeze([
  { text: 'Created date', value: 'created', width: '30%' },
  { text: 'Status', value: 'status', width: '20%' },
  { text: 'Description', value: 'description' },
]);

export default {
  name: 'TrackingInfoViewer',
  props: {
    orderId: {
      type: [String, Number],
      default: '',
    },
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
      activitiesListHeaders,
    };
  },
  computed: {
    ...mapState('orders', ['trackingInfo']),
  },
  methods: {
    ...mapActions('orders', ['getTrackingInfoByOrderId']),
    async openModalWindow() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getTrackingInfoByOrderId({ id: this.orderId });
        if (this.trackingInfo.length) {
          this.dialog = true;
        } else {
          this.$VBlackerTheme.notification.warning('Tracking information is empty');
        }
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
