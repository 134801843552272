import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const OrderChargesApiService = {
  async getChargesByOrderId({ orderId }) {
    try {
      const { data } = await HTTP.get(`charges/${orderId}`);

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getChargesCodes() {
    try {
      const { data } = await HTTP.get('charges/charge-codes');

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async addCharge(data) {
    try {
      return await HTTP.post('charges', data);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
