<template>
  <vbt-dialog
    v-model="dialog"
    :disable-submit-btn="isInvalidForm"
    title="Add Charge"
    submit-btn-name="Add"
    width="500px"
    @submit="addCharge"
    @close="resetForm"
  >
    <template #activator="{ on }">
      <v-btn
        :loading="chargesCodesLoading"
        color="success"
        x-small
        v-on="on"
      >
        Add Charge
      </v-btn>
    </template>

    <v-select
      v-model="accountId"
      :items="accountsOptions"
      label="Account"
    />

    <v-select
      v-model="orderItemId"
      :items="orderItemOptions"
      label="Order Item ID"
    />

    <v-select
      v-model="chargeType"
      :items="chargeTypes"
      label="Charge Type"
    />

    <v-select
      v-model="chargeCode"
      :items="chargesCodes"
      label="Charge Code"
    />

    <vbt-date-picker v-model="dateReleased" />

    <v-text-field
      v-model.trim.number="quantity"
      label="Quantity"
      type="number"
    />

    <v-text-field
      v-model.trim.number="amount"
      label="Amount"
      type="number"
    />

    <v-textarea
      v-model.trim="comment"
      label="Comment"
      class="mt-4"
      outlined
    />
  </vbt-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { OrderChargesApiService } from '../../../_services/charges.api.service';

const REQUIRED_FORM_FIELDS = [
  'dateReleased', 'amount', 'chargeCode', 'comment',
];

export default {
  name: 'ChargeAdder',

  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      accountId: 0,
      orderItemId: null,
      chargeType: '',
      chargeCode: '',
      quantity: null,
      amount: null,
      comment: '',
      dateReleased: '',

      dialog: false,
      chargesCodes: [],
      chargesCodesLoading: false,
    };
  },

  computed: {
    ...mapState('enums', {
      chargeTypes: ({ enums: { chargeRecordTypes } }) => (chargeRecordTypes || [])
        .map(({ value, name: text }) => ({ value, text })),
    }),


    ...mapGetters('accounts', ['accountsOptions']),
    ...mapGetters('orders', ['orderItems']),

    orderItemOptions() {
      return this.orderItems.map(({
        id, quantity, productType, printGroupId,
      }) => ({
        value: id,
        text: `${quantity} ${productType} (${printGroupId})`,
      }));
    },

    isInvalidForm() {
      return REQUIRED_FORM_FIELDS.some(f => !this[f]);
    },
  },

  created() {
    this.getChargesCodes();
  },

  methods: {
    getChargesCodes() {
      this.wrapToLoadingFn({
        req: OrderChargesApiService.getChargesCodes,
        loadingFlagName: 'chargesCodesLoading',
        onSuccess: r => this.$set(this, 'chargesCodes', r),
      });
    },

    addCharge() {
      this.wrapToLoadingFn({
        req: OrderChargesApiService.addCharge.bind(null, {
          orderId: this.orderId,
          accountId: this.accountId,
          orderItemId: this.orderItemId || null,
          chargeType: this.chargeType,
          chargeCode: this.chargeCode,
          quantity: this.quantity,
          amount: this.amount,
          comment: this.comment,
          dateReleased: this.dateReleased,
        }),
        loadingFlagName: 'chargesCodesLoading',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully added');
          this.$emit('success');
          this.dialog = false;
        },
      });
    },

    resetForm() {
      Object.assign(this, {
        accountId: 0,
        orderItemId: null,
        chargeType: '',
        chargeCode: '',
        quantity: null,
        amount: null,
        comment: '',
        dateReleased: '',
      });
    },

    wrapToLoadingFn,
  },
};
</script>
