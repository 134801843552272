import { mapGetters } from 'vuex';

const orderRouteResultsListHeaders = Object.freeze([
  { text: 'Order Item ID', value: 'orderItemId' },
  { text: 'Route Date', value: 'dateCreated' },
  { text: 'Print Provider', value: 'printProvider' },
  { text: 'Route Rule', value: 'routeRule' },
]);

export default {
  name: 'OrderRouteResults',
  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },
  data() {
    return {
      isShort: false,
      orderRouteResultsListHeaders,
    };
  },
  computed: {
    ...mapGetters('orders', ['orderRouteResults']),
    rows() {
      return this.isShort ? this.orderRouteResults.slice(0, 3) : this.orderRouteResults;
    },
    noContent() {
      return { show: !this.orderRouteResults.length, text: 'No route results' };
    },
  },
};
