<template>
  <v-menu
    close-delay="100"
    nudge-left="30"
    nudge-top="25"
    open-on-hover
    left
  >
    <template #activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
      >
        <v-icon>
          more_vert
        </v-icon>
      </v-btn>
    </template>

    <v-layout column>
      <v-btn
        small
        color="info"
        style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
        @click="openHistory"
      >
        Show History
      </v-btn>

      <v-btn
        small
        color="success"
        style="border-radius: 0;"
        @click="markDelivered"
      >
        Mark Delivered
      </v-btn>

      <v-btn
        small
        color="error"
        style="border-top-left-radius: 0; border-top-right-radius: 0;"
        @click="cancelOrderItem"
      >
        Cancel
      </v-btn>
    </v-layout>

    <vbt-dialog
      v-model="historyDialog"
      :title="historyTitle"
      hide-submit-btn
      width="60%"
    >
      <vbt-table
        :headers="historyHeaders"
        :items="historyItems"
      >
        <template #printProviderId="{ item: { printProviderId } }">
          {{ accountNameByIdFromAllAccounts[printProviderId] }}
        </template>

        <template #created="{ item: { created } }">
          {{ formatDisplayDate(created) }}
        </template>
      </vbt-table>
    </vbt-dialog>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';

import { wrapToLoadingFn, formatDisplayDate } from '@helpers';

import { OrdersApiService } from '../../../_services/orders.api.service';

const historyHeaders = Object.freeze([
  { text: 'Status', value: 'state' },
  { text: 'Print Provider', value: 'printProviderId' },
  { text: 'Created Date', value: 'created' },
  { text: 'Description', value: 'description' },
]);

export default {
  name: 'OrderItemActions',

  props: {
    orderId: {
      type: Number,
      required: true,
    },

    orderItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      historyDialog: false,
      historyItems: [],
      historyHeaders,
    };
  },

  computed: {
    ...mapGetters('accounts', ['accountNameByIdFromAllAccounts']),

    historyTitle() {
      return `Order item ${this.orderItem.id} history`;
    },
  },

  methods: {
    openHistory() {
      this.wrapToLoadingFn({
        req: OrdersApiService.getOrderItemHistory.bind(null, {
          orderItemId: this.orderItem.id,
          orderId: this.orderId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: (r) => {
          if (!r.length) {
            this.$VBlackerTheme.notification.warning('No history items');
          } else {
            this.$set(this, 'historyItems', r);
            this.historyDialog = true;
          }
        },
      });
    },

    markDelivered() {
      this.$VBlackerTheme.alert.warning({
        text: `Mark ${this.orderItem.productType} delivered?`,
      }, () => this.wrapToLoadingFn({
        req: OrdersApiService.markOrderItemDelivered.bind(null, {
          orderItemId: this.orderItem.id,
          orderId: this.orderId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully delivered');
          this.$emit('refresh');
        },
      }));
    },

    cancelOrderItem() {
      this.$VBlackerTheme.alert.warning({
        text: `Cancel ${this.orderItem.productType}?`,
      }, () => this.wrapToLoadingFn({
        req: OrdersApiService.cancelOrderItem.bind(null, {
          generatedId: this.orderItem.generatedId,
          orderId: this.orderId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully canceled');
          this.$emit('refresh');
        },
      }));
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
