import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const OrdersApiService = {
  async runMassReroute({
    printProvider,
    generatedIds,
    orderId,
    force,
    reestimate,
  }) {
    try {
      return await HTTP.post(
        `operations/${orderId}/reroute/range`,
        {
          printProvider,
          generatedIds,
        },
        {
          params: {
            force,
            reestimate,
          },
        },
      );
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getMassRerouteAccounts({
    printProvider,
    generatedIds,
    orderId,
    force,
  }) {
    try {
      const { data } = await HTTP.post(
        `operations/${orderId}/reroute/accounts`,
        {
          printProvider,
          generatedIds,
        },
        {
          params: {
            force,
          },
        },
      );

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getTags() {
    try {
      const { data } = await HTTP.get('orders/tags');
      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getCategoriesWithTags() {
    try {
      const { data } = await HTTP.get('orders/tags');
      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async markOrderItemDelivered({ orderId, orderItemId }) {
    try {
      return await HTTP.post(`operations/${orderId}/mark-delivered/${orderItemId}`);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async cancelOrderItem({ orderId, generatedId }) {
    try {
      return await HTTP.delete(`operations/${orderId}/${generatedId}`);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getOrderItemHistory({ orderId, orderItemId }) {
    try {
      const { data } = await HTTP.get(`orders/${orderId}/item/${orderItemId}/history`);
      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
