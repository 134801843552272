import { mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { SHORT_PROJECT_NAME } from '@config/application';

import OrderTags from '../../_components/OrderTags.vue';
import OrderInfo from '../../_components/orderInfo';
import OrderItems from '../../_components/orderItems';
import OrderRouteResults from '../../_components/orderRouteResults';
import OrderPrices from '../../_components/orderPrices';
import OrderMessages from '../../_components/orderMessages';
import OrderAlerts from '../../_components/orderAlerts';
import IrOrderCharges from '../../_components/IrOrderCharges';

const dataLoadingOptions = {
  getterName: 'getOrder', searchable: false, paged: false, parameterize: true,
};

export default {
  name: 'Orders',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  components: {
    OrderTags,
    OrderInfo,
    OrderItems,
    OrderRouteResults,
    OrderPrices,
    OrderMessages,
    OrderAlerts,
    IrOrderCharges,
  },

  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },

  watch: {
    '$route.params.id': {
      handler(orderId) {
        if (orderId) {
          document.title = `${this.$route.meta.title} ${orderId} | ${SHORT_PROJECT_NAME}`;
        }
      },
      immediate: true,
    },
  },

  created() {
    this.getAccounts();
    this.getAllAccounts();
  },

  methods: {
    ...mapActions('orders', ['getOrder']),
    ...mapActions('accounts', ['getAccounts', 'getAllAccounts']),
    refreshData() {
      this.allowGettingData = true;
    },
  },
};
