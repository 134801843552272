import { mapState } from 'vuex';

const orderAlertsListHeaders = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Timestamp', value: 'timestamp' },
  { text: 'Reference ID', value: 'referenceId' },
  { text: 'Type', value: 'errorType' },
  { text: 'Comment', value: 'comment' },
  { text: 'Cleared', value: 'cleared' },
]);

export default {
  name: 'OrderAlerts',
  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    isLoading: Boolean,
  },
  data() {
    return {
      isShort: true,
      orderAlertsListHeaders,
    };
  },
  computed: {
    ...mapState('orders', ['order']),
    orderAlerts() {
      return this.order.sendErrors || [];
    },
    rows() {
      return this.isShort ? this.orderAlerts.slice(0, 3) : this.orderAlerts;
    },
    noContent() {
      return { show: !this.orderAlerts.length, text: 'No alerts' };
    },
  },
};
